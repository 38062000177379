import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import AOS from 'aos'

import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/800.css"
import "@fontsource/poppins/900.css"

import "@fontsource/athiti/300.css"
import "@fontsource/athiti/400.css"
import "@fontsource/athiti/500.css"
import "@fontsource/athiti/600.css"

import "@fontsource/saira/200.css"
import "@fontsource/saira/300.css"
import "@fontsource/saira/400.css"
import "@fontsource/saira/500.css"
import "@fontsource/saira/600.css"

import 'aos/dist/aos.css'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

document.addEventListener('turbolinks:load', function () {
  AOS.init({
    once: true,
    duration: 600
  })
})