import {
  Controller
} from 'stimulus'

import 'particles.js/particles';
const particlesJS = window.particlesJS;

export default class extends Controller {
  static targets = []

  connect() {
    particlesJS.load('particles', '/contact_cta_particles_settings.json');
  }
}