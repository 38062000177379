import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'


import 'alpinejs'
import 'tailwindcss/tailwind.css'
import SweetScroll from 'sweet-scroll';

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  const sweetScroll = new SweetScroll({ /* some options */ });

}, false);