import {
  Controller
} from 'stimulus'

import Swiper, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination
} from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination, EffectFade])

export default class extends Controller {
  static targets = ['next', 'prev', 'container', 'pagination', 'mobilePrev', 'mobileNext', 'mobilePagination']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      pagination: {
        el: [this.paginationTarget, this.mobilePaginationTarget],
        type: 'bullets',
        clickable: true
      },
      autoplay: {
        delay: 10000,
      },
      navigation: {
        nextEl: [this.nextTarget, this.mobileNextTarget],
        prevEl: [this.prevTarget, this.mobilePrevTarget],
      }
    })
  }
}