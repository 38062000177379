import {
  Controller
} from 'stimulus'

import Swiper, {
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
  Controller as SwiperController
} from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([EffectFade, SwiperController, Navigation])

export default class extends Controller {
  static targets = ['contentContainer', 'imageContainer', 'next', 'prev', 'mobileNext', 'mobilePrev', 'paginationContainer', 'timelineBar', 'timelineSection']

  connect() {
    const imageSwiper = new Swiper(this.imageContainerTarget, {
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    })


    // Collect content to display above each bullet for timeline

    let yearsArray = [...this.contentContainerTarget.querySelectorAll('[data-slide-year]')]

    yearsArray = yearsArray.map((item) => {
      return item.innerText
    })

    const contentSwiper = new Swiper(this.contentContainerTarget, {
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 8000,
      },

      navigation: {
        nextEl: [this.nextTarget, this.mobileNextTarget],
        prevEl: [this.prevTarget, this.mobilePrevTarget]
      },

      pagination: {
        el: this.paginationContainerTarget,
        clickable: true,

        renderBullet: function (index, className) {
          return `<div class="${className}">
                    <p class="absolute bottom-4 left-1/2 transform -translate-x-1/2 bullet-text">
                      ${yearsArray[index]}
                    </p>
                  </div>`
        },
      },
    });




    // Timeline Slider Bar width and Bullet control

    const sliderCount = this.timelineSectionTarget.dataset.sliderCount
    contentSwiper.on('activeIndexChange', () => {

      let slideIndex = contentSwiper.activeIndex - 1
      let desiredWidth = `${slideIndex * (100/ (sliderCount - 1))}%`

      this.timelineBarTarget.style.maxWidth = (slideIndex !== 0 ? desiredWidth : "0%")

      let bulletArray = [...document.querySelectorAll('.timeline-slider .swiper-pagination-bullet')]
      let bulletArrayClone = [...document.querySelectorAll('.timeline-slider .swiper-pagination-bullet')]

      let pastBullets = bulletArray.slice(0, slideIndex)
      pastBullets.forEach((bullet) => {
        bullet.classList.add('past-bullet')
      })

      if (slideIndex != (sliderCount - 1)) {
        let futureBullets = bulletArrayClone.slice(`-${sliderCount - (slideIndex + 1)}`)

        for (let item of futureBullets) {
          item.classList.remove('past-bullet')
        }
      }
    })


    // Swiper Connections
    contentSwiper.controller.control = imageSwiper
    imageSwiper.controller.control = contentSwiper
  }
}